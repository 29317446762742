// extracted by mini-css-extract-plugin
export var authorHead = "post-module--authorHead--59d20";
export var dateHead = "post-module--dateHead--890ec";
export var featuredImage = "post-module--featuredImage--37343";
export var flower = "post-module--flower--1c73a";
export var form = "post-module--form--6764b";
export var header = "post-module--header--e3015";
export var pageContent = "post-module--pageContent--22bb1";
export var paw = "post-module--paw--c131c";
export var post = "post-module--post--d3eb9";
export var squiggle = "post-module--squiggle--697ab";